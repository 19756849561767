import axios, { AxiosInstance, AxiosResponse } from "axios";

export abstract class AxiosClient {
    protected readonly client: AxiosInstance;

    protected constructor(baseURL: string) {
        this.client = axios.create({
            baseURL,
            headers: {
                "Content-Type": "application/json"
            }
        });
        this._initialiseResponseInterceptor();
    }

    private _initialiseResponseInterceptor = () => {
        this.client.interceptors.response.use(
            this._handleResponse,
            this._handleError
        );
    };

    private _handleResponse = ({ data, status, headers, request }: AxiosResponse) => {
        /*console.log(headers["x-rate-limit-api-token-interval-ms"]);*/
        if (status === 429) {
            setTimeout(() => {
                console.log("Rate Limit");
                //console.log(request.responseURL);
                //return this.client.request(request.responseURL);
            }, headers["x-rate-limit-api-token-interval-ms"]);
        }
        return data;
    };
    protected _handleError = (error: any) => Promise.reject(error);
}