import {AxiosClient} from "@/api/trello/AxiosClient";
import { AxiosRequestConfig } from 'axios';

export class TrelloClient extends AxiosClient {
    protected static TRELLO_API_URL = 'https://api.trello.com/1/';
    constructor() {
        super(TrelloClient.TRELLO_API_URL);
        this.client.defaults.headers.get["Accept"] = "application/json";
        this.client.defaults.headers.post["Accept"] = "application/json";
        this._initialiseRequestInterceptor();
    }
    private _initialiseRequestInterceptor = () => {
        this.client.interceptors.request.use(
            this._handleRequest,
            this._handleError
        );
    };

    private _handleRequest = (config: AxiosRequestConfig) => {
        config.params = {
            key: "",
            token: ""
        };
        return config;
    };
}
