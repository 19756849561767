import {TrelloClient} from "@/api/trello/TrelloClient";
import {Status} from "@/api/indexeddb/types";

export class TrelloAPI extends TrelloClient{

    constructor() {
        super();
    }

    public getMember(username?: string) {
        const memberUsername = username || 'me';
        return this.client.get(`/members/${memberUsername}`);
    }
    public getBoards = (id?: string) => {
        const memberId = id || 'me';
        return this.client.get(`/members/${memberId}/boards`);
    }

    public getListsOnBoard = (id: string) => {
        return this.client.get(`/boards/${id}/lists`);
    }

    public getCardsForList(id: string) {
        return this.client.get(`/lists/${id}/cards`);
    }
    public getCard(id: string) {
        return this.client.get(`/cards/${id}?fields=id,name,idBoard,idList,idMembers,idShort,shortUrl&members=true&customFieldItems=true&list=true&actions=commentCard,updateCard:name&filter=updateCard:data`);
    }

    public getCardMembers(id: string) {
        return this.client.get(`/cards/${id}/members`);
    }
}